import { MembershipT } from "../types"
import { isClient } from "../utils"

/**
 * APP
 */

export const FIRESTORE_BLOG_VIEWS_API_BASE =
  "https://harborschool-blog-views.vercel.app/api"
// url from Embedded forms https://us16.admin.mailchimp.com/lists/integration/embeddedcode?id=80899
// update confirmation email design at here https://us16.admin.mailchimp.com/lists/designer/?id=80899

export const DYNAMIC_OG_IMAGE_API_BASE =
  "https://dynamic-og-image-six.vercel.app"

/**
 * Commerce
 */
export const STRIPE_API_KEY = "pk_live_bavPcuL9aoMmlNGm3Y07ybjN"
export const STRIPE_SERVERLESS_API_BASE =
  "https://w32nhd5n3e.execute-api.us-west-2.amazonaws.com/prod"
export const STRIPE_SUBSCRIPTION_TRIAL_DAYS = 0

export const WHITE_LISTED_DOMAIN = ["harbor.school", "framer.com", "ruucm.work"]
export const SUBSCRIPTION_TRIAL_DAYS = 3

export const DISCORD_BOT = "https://discord-moomin-alx8.onrender.com"

export const MEMBERSHIP: {
  yearly: MembershipT
  monthly: MembershipT
  test: MembershipT
} = {
  yearly: {
    key: "yearly",
    plan: "price_1Ih7QXKeWJo6vx082VyWSSfD",
    price: 70800, // $59 * 12
    sale_price: 23900, // $19.9 * 12
    interval: "year",
  },
  monthly: {
    key: "monthly",
    plan: "price_1Ih7PwKeWJo6vx08ZKuaLmEk",
    price: 5900,
    sale_price: 3700,
    interval: "month",
  },
  test: {
    key: "test",
    plan: "price_1IkjMaKeWJo6vx08NxieSi6o",
    price: 100,
    interval: "day",
  },
}
export const COUPON = {
  YOURHARBORSCHOOL: "dooSNkcx",
  PH1117: "M3o9Wtlp",
  HB_Y1A: "qLdJ3rzF",
  HB_LWV: "qLdJ3rzF",
  HB_LMA: "qLdJ3rzF",
  HB_MMY: "qLdJ3rzF",
  HB_LC7: "qLdJ3rzF",
  HB_GDN: "qLdJ3rzF",
  HB_F3J: "qLdJ3rzF",
  HB_CH0: "qLdJ3rzF",
  HB_4L7: "qLdJ3rzF",
  HB_O23: "qLdJ3rzF",
  HB_WTE: "qLdJ3rzF",
  HB_R8M: "qLdJ3rzF",
  HB_FB8: "qLdJ3rzF",
  HB_BJA: "qLdJ3rzF",
  HB_4SQ: "qLdJ3rzF",
  HB_40G: "qLdJ3rzF",
  HB_8F3: "qLdJ3rzF",
  HB_2TN: "qLdJ3rzF",
  HB_MTC: "qLdJ3rzF",
  HB_VOM: "qLdJ3rzF",
  HB_JTR: "qLdJ3rzF",
  HB_HLI: "qLdJ3rzF",
  HB_HG5: "qLdJ3rzF",
  HB_N2Z: "qLdJ3rzF",
  HB_PR3: "qLdJ3rzF",
  HB_SZV: "qLdJ3rzF",
  HB_DR5: "qLdJ3rzF",
  HB_F69: "qLdJ3rzF",
  HB_TOE: "qLdJ3rzF",
  HB_64I: "qLdJ3rzF",
  HB_VCL: "qLdJ3rzF",
  HB_HSX: "qLdJ3rzF",
  HB_69A: "qLdJ3rzF",
  HB_16I: "qLdJ3rzF",
  HB_9LR: "qLdJ3rzF",
  HB_7Q4: "qLdJ3rzF",
  HB_WBR: "qLdJ3rzF",
  HB_RLB: "qLdJ3rzF",
  HB_3Z3: "qLdJ3rzF",
  HB_IKO: "qLdJ3rzF",
  HB_GFS: "qLdJ3rzF",
  HB_RXG: "qLdJ3rzF",
  HB_9KZ: "qLdJ3rzF",
  HB_42M: "qLdJ3rzF",
  HB_3FC: "qLdJ3rzF",
  HB_IOT: "qLdJ3rzF",
  HB_3RD: "qLdJ3rzF",
  HB_30U: "qLdJ3rzF",
  HB_2W4: "qLdJ3rzF",
  HB_QPM: "qLdJ3rzF",
  HB_1Z6: "qLdJ3rzF",
  HB_CV1: "qLdJ3rzF",
  HB_83I: "qLdJ3rzF",
  HB_Z5N: "qLdJ3rzF",
  HB_D0L: "qLdJ3rzF",
  HB_9Q0: "qLdJ3rzF",
  HB_WNS: "qLdJ3rzF",
  HB_IGJ: "qLdJ3rzF",
  HB_R9Q: "qLdJ3rzF",
  HB_2DR: "qLdJ3rzF",
  HB_WZN: "qLdJ3rzF",
  HB_H15: "qLdJ3rzF",
  HB_A7Y: "qLdJ3rzF",
  HB_A9O: "qLdJ3rzF",
  HB_1WQ: "qLdJ3rzF",
  HB_XC4: "qLdJ3rzF",
  HB_8ZQ: "qLdJ3rzF",
  HB_OSC: "qLdJ3rzF",
  HB_53L: "qLdJ3rzF",
  HB_O6P: "qLdJ3rzF",
  HB_AHM: "qLdJ3rzF",
  HB_1QC: "qLdJ3rzF",
  HB_0XS: "qLdJ3rzF",
  HB_TTR: "qLdJ3rzF",
  HB_03F: "qLdJ3rzF",
  HB_K8I: "qLdJ3rzF",
  HB_GDI: "qLdJ3rzF",
  HB_1NU: "qLdJ3rzF",
  HB_88K: "qLdJ3rzF",
  HB_UPM: "qLdJ3rzF",
  HB_E9F: "qLdJ3rzF",
  HB_1EO: "qLdJ3rzF",
  HB_HQB: "qLdJ3rzF",
  HB_A9Y: "qLdJ3rzF",
  HB_YBN: "qLdJ3rzF",
  HB_VW8: "qLdJ3rzF",
  HB_92B: "qLdJ3rzF",
  HB_7KS: "qLdJ3rzF",
  HB_QXD: "qLdJ3rzF",
  HB_ISM: "qLdJ3rzF",
  HB_8N9: "qLdJ3rzF",
  HB_JQU: "qLdJ3rzF",
  HB_MTF: "qLdJ3rzF",
  HB_DHT: "qLdJ3rzF",
  HB_2HJ: "qLdJ3rzF",
  HB_BLN: "qLdJ3rzF",
  HB_PV2: "qLdJ3rzF",
  HB_P8S: "qLdJ3rzF",
  HB_0VF: "qLdJ3rzF",
  HB_PGF: "qLdJ3rzF",
}
export const ROLE = {
  ADMIN: "ADMIN",
  VIP: "VIP",
}

export const CHANNEL_TALK = {
  RUUCM: `(function() {
      var w = window;
      if (w.ChannelIO) {
        return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
      }
      var ch = function() {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function(args) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        s.charset = 'UTF-8';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (document.readyState === 'complete') {
        l();
      } else if (window.attachEvent) {
        window.attachEvent('onload', l);
      } else {
        window.addEventListener('DOMContentLoaded', l, false);
        window.addEventListener('load', l, false);
      }
    })();
    ChannelIO('boot', {
      "pluginKey": "93e1028b-ea18-4893-9189-f187749d77b2"
    });`,
  HARBOR_SCHOOL: `(function() {
    var w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
    }
    var ch = function() {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function(args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  })();
  ChannelIO('boot', {
    "pluginKey": "2a2bc72a-510d-4ee4-bbb8-c214975c26e9"
  });`,
}

/**
 * Roadmap
 */
export const ROADMAP_EMBED_URL = {
  ko:
    "https://framer.com/embed/Framer-Beginner-s-Roadmap-2021-WIP--rZuaQXFFVjgOdHIsG7wH/F5wj7vNlT",
  en:
    "https://framer.com/embed/Framer-Beginner-s-Roadmap-2021-WIP--rZuaQXFFVjgOdHIsG7wH/niNdlQq19",
}

/**
 * Brand & Marketing
 */
export const PROMO_VIDEOS = {
  course: {
    "framer-the-complete-guide": "https://www.youtube.com/watch?v=KIbAXnuXyd0",
    "react-design-system": "https://www.youtube.com/watch?v=nSxBeopwA7E",
    "clone-apple-website-framer": "https://www.youtube.com/watch?v=Q2_1K8C0uUU",
  },
}

/**
 * UI
 */
export const HEADER_LOGO_HEIGHT = 24
export const HEADER_HEIGHT = ({ theme }) =>
  `${HEADER_LOGO_HEIGHT}px + ${theme.sizing.scale900} * 2`
// OSX (Chrome, Safari, Firefox) - 15px.
// Windows 10 (IE11, Chrome, Firefox) - 17px.
export const BROWSER_SCROLL_BAR_WIDTH = 17

export const USER_TYPES = {
  GUEST: "GUEST",
  USER: "USER",
  MEMBER: "MEMBER",
  TRIAL: "TRIAL",
  ADMIN: "ADMIN",
}
export const MODAL = {
  SIGN_IN: "sign-in",
  SIGN_UP: "sign-up",
  BECOME_A_MEMBER: "become-a-member",
  PAUSE_SUBSCRIPTION: "pause-subscription",
  RESUME_SUBSCRIPTION: "resume-subscription",
  CANCEL_SUBSCRIPTION: "cancel-subscription",
  CANCEL_SUBSCRIPTION_AT_END: "cancel-subscription",
  UPDATE_NAME: "update-name",
  UPDATE_PAYMENT: "update-payment",
  WAIT_LIST: "waitlist-subscription",
  NEWS_LETTER: "news-letter",
  SEARCH: "search",
}

export const NAVIGATE = {
  COURSE: "COURSE",
  LEVEL: "LEVEL",
  POST: "POST",
}

export const VIDEO_RATIO = 1280 / 2048 /* Player ratio: 100 / (1280 / 2048) */
export const CANVAS_HEIGHT =
  // @ts-ignore
  ((isClient && window.innerWidth - 20) / 2) * VIDEO_RATIO

/**
 * Animation
 */
export const framerDefaultTransition = {
  shiftness: 500,
  damping: 60,
  mass: 1,
}

/**
 * Video Scrubber (Explorer)
 */
export const CANVAS_DEFAULT_FPS = 3
export const CANVAS_RESOLUTION = 1080

export const SECTION_SUMMARY_CLASSNAME = "section-summary"
export const SECTION_DETAIL_STEPS_CLASSNAME = "section-detail-steps"
export const FOCUS_POINT = 0.2
export const SCROLL_FOCUS_POINT: any =
  isClient && window.innerHeight * FOCUS_POINT

export const veScrollRevealDelay = SCROLL_FOCUS_POINT + 200
export const lastStepFastForwarded = CANVAS_HEIGHT - SCROLL_FOCUS_POINT

export const READING_TIME_RATIO = 3

/**
 * Admin (Temp)
 */
export const tempPassword = "0219"

export const NOT_ALLOWED_EMAIl_DOMAINS = ["@ruu.kr"]
